<style>
.tabs .div {
  display: flex;
  justify-content: center;
}
</style>
<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <b-row class="match-height">
              <b-col cols="12">
                <tab-position :tabs="tabs" :name="'Project Mapping'" />
              </b-col>
            </b-row> -->
            <b-tabs
              horizontal
              content-class=" mt-1 mt-md-0"
              pills
              nav-wrapper-class="div"
              nav-class="nav-left"
            >
              <!-- general tab -->
              <b-tab @click="changeTab" active>
                <!-- title -->
                <template #title>
                  <feather-icon icon="EditIcon" size="18" class="mr-50" />
                  <span class="font-weight-bold">Edit</span>
                </template>
                <b-row>
                  <b-col class="mb-5" md="4">
                    <b-form-group>
                      <label>Select Project</label>
                      <v-select
                        v-model="selectedProject"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="name"
                        :options="projectOption"
                        placeholder="None"
                        @input="getPlot($event.id)"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    md="12"
                    v-if="visible"
                    class="d-flex align-items-center overflow-auto"
                  >
                    <image-map
                      :value="phaseImage"
                      :projectId="projectId"
                      :plotOption="plotOption"
                      @input="(zones) => change({ zones })"
                    />
                  </b-col>
                  <!-- <b-col class="d-flex justify-content-between align-items-center" md="2">
                <b-button size="sm" variant="primary" @click="handleBook()">
                  Edit
                </b-button>
                <b-button size="sm" variant="primary" @click="handleBook()">
                  Stop Edit
                </b-button>
              </b-col> -->
                </b-row>
              </b-tab>
              <!-- general tab -->
              <b-tab @click="changeTab">
                <!-- title -->
                <template #title>
                  <feather-icon icon="EyeIcon" size="18" class="mr-50" />
                  <span class="font-weight-bold">View</span>
                </template>
                <b-row>
                  <b-col class="mb-5" md="4">
                    <b-form-group>
                      <label>Select Project</label>
                      <v-select
                        v-model="selectedProject"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="name"
                        :options="projectOption"
                        placeholder="None"
                        @input="getPlot($event.id)"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="12" v-if="visible" class="d-flex align-items-center h-auto">
                    <ImageMapView
                      :value="phaseImage"
                      :projectId="projectId"
                      :plotOption="plotOption"
                      :selectedProject="selectedProject"
                      @input="(zones) => change({ zones })"
                    />
                  </b-col>
                  <!-- <b-col class="d-flex justify-content-between align-items-center" md="2">
                <b-button size="sm" variant="primary" @click="handleBook()">
                  Edit
                </b-button>
                <b-button size="sm" variant="primary" @click="handleBook()">
                  Stop Edit
                </b-button>
              </b-col> -->
                </b-row>
              </b-tab>
            </b-tabs>
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageMap from "../../../../components/ImageMap.vue";
import ImageMapView from "../../../../components/ImageMapView.vue";
import TabPosition from "../../../../components/TabView.vue";
import image from "../../../../assets/images/pages/no-image.png";
import PinchScrollZoom from "@coddicat/vue-pinch-scroll-zoom";
import {
  BAvatar,
  BCard,
  BBadge,
  BCardHeader,
  BCardTitle,
  BFormInput,
  BFormGroup,
  BCardBody,
  BRow,
  BCol,
  BCardText,
  BButton,
  BFormRadio,
  BBreadcrumb,
  BBreadcrumbItem,
  BDropdown,
  BDropdownItem,
  BImgLazy,
  BTabs,
  BTab,
} from "bootstrap-vue";
import vSelect from "vue-select";
import axios from "@/components/axios";

export default {
  name: "app",
  components: {
    ImageMap,
    BFormRadio,
    TabPosition,
    BFormGroup,
    BButton,
    vSelect,
    BRow,
    BFormInput,
    BCol,
    ImageMapView,
    BImgLazy,
    BTabs,
    BTab,
  },
  data() {
    return {
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken: localStorage.getItem("accessToken"),
      // tabs: [
      //   { name: 'Lead Source', route: '/master/crm/leadsource', icon: 'CompassIcon' },
      //   { name: 'Lead Stage', route: '/master/crm/leadstage', icon: 'CommandIcon' },
      //   { name: 'Campaign Name', route: '/master/crm/campaign', icon: 'MinimizeIcon' },
      //   { name: 'Reason', route: '/master/crm/reason', icon: 'ApertureIcon' },
      //   { name: 'Channel Partner', route: '/master/crm/channelpartner', icon: 'UsersIcon' },
      //   { name: 'Franchise', route: '/master/crm/franchise', icon: 'FramerIcon' },
      //   { name: 'Project', route: '/master/crm/project', icon: 'PackageIcon' },
      //   { name: 'Plot', route: '/master/crm/plot', icon: 'MapIcon' },
      //   { name: 'Land', route: '/master/crm/land', icon: 'SlackIcon' },
      //   { name: 'Commission', route: '/master/crm/commission', icon: 'GitCommitIcon' },
      //   { name: 'Project Mapping', route: '/master/crm/projectmapping', icon: 'MapPinIcon' }
      // ],
      tab: [
        { name: "Edit", route: "/master/crm/leadsource", icon: "CompassIcon" },
        {
          name: "Lead Stage",
          route: "/master/crm/leadstage",
          icon: "CommandIcon",
        },
      ],
      value: {
        uuid: 3721,
        source: "http://n.sinaimg.cn/news/transform/20170330/K2Gk-fycwymx2694027.jpg",
      },
      visible: false,
      selectedProject: "",
      projectOption: [],
      plotOption: [],
      phaseImage: {
        uuid: 3721,
        source: image,
      },
      slectedValue: false,
      projectId: "",
      isMobile: window.innerWidth,
    };
  },
  mounted() {
    const userData = JSON.parse(localStorage.getItem("UserRights"));
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (role !== "admin") {
      userData.map((item) => {
        if (item.modulename == "Project Mapping") {
          if (this.$route.params.id) {
            if (item.edit !== 1) {
              this.$router.push("/master/crm/projectmapping");
            }
          } else if (item.add !== 1) {
            this.$router.push("/master/crm/projectmapping");
          }
        }
      });
    }
    this.getProject();
  },
  methods: {
    changeTab() {
      this.selectedProject = null;
      this.visible = false;
    },
    radioClicked(id) {
      this.slectedValue = 1;
    },
    change(changes) {},
    handelStartMedger(id) {},
    async getProject() {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/project`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.projectOption = response.data.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },

    async getAttachment(id) {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getattachment`,
        data: JSON.stringify({
          project_id: id,
        }),
      };
      await axios(requestOptions)
        .then((response) => {
          this.phaseImage.source = response.data.data ? response.data.data : image;
        })
        .catch((error) => console.log(error, "error"));
    },
    async getPlot(id) {
      this.projectId = id;
      this.plotOption = [];
      this.getAttachment(id);
      const requestOptions = {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getallplotbyproject/${id}`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.visible = true;
          this.plotOption = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
  },
};
</script>

<style></style>
